<template>
  <div>
    <ion-router-outlet/>
    <ion-modal :is-open="showOfflineModal" @didDismiss="showOfflineModal=false">
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-title>
              Connection Error
            </ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-card>
            <ion-card-content>
              You are currently offline. <br/>
              Please connect to the internet before continuing. <br/> <br/>
              <ion-button @click="checkConnection" :disabled="checking" color="medium">
                <ion-spinner v-if="checking"/>
                <div v-else>
                  <ion-icon :ios="refresh" :md="refresh"/>
                  Retry
                </div>
              </ion-button>
            </ion-card-content>
          </ion-card>
        </ion-content>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script>
import {
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonModal,
  IonPage,
  IonCard,
  IonCardContent,
  IonButton,
  IonSpinner,
  IonIcon,
} from '@ionic/vue'
import { refresh } from 'ionicons/icons'
import { Network } from '@capacitor/network'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'MainContainer',
  components: {
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonModal,
    IonPage,
    IonCard,
    IonCardContent,
    IonButton,
    IonSpinner,
    IonIcon,
  },
  setup () {
    return {
      refresh,
    }
  },
  data () {
    return {
      listenerAdded: false,
      online: true,
      showOfflineModal: false,
      checking: false,
    }
  },
  methods: {
    openOfflineModal () {
      let modal = document.getElementsByTagName('ion-modal')[0]
      this.checking = false
      if (this.showOfflineModal && modal) {
        modal.dismiss().then(() => {
          this.showOfflineModal = true
        })
      } else {
        this.showOfflineModal = true
      }
    },
    closeOfflineModal () {
      let modal = document.getElementsByTagName('ion-modal')[0]
      if (this.showOfflineModal && modal) {
        modal.dismiss()
        this.checking = false
      }
    },
    toggleOfflineModal (online) {
      this.online = online
      if (this.online && this.showOfflineModal) {
        this.closeOfflineModal()
      } else if (!this.online && !this.showOfflineModal) {
        this.openOfflineModal()
      }
    },
    checkConnection () {
      if (this.checking) {return}
      this.checking = true
      Network.getStatus().then(({connected}) => {
        this.toggleOfflineModal(connected)
        this.checking = false
      })
    },
  },
  created () {
    if (Capacitor.isPluginAvailable('Network')) {
      this.checkConnection()
      Network.addListener('networkStatusChange', ({connected}) => {
        this.toggleOfflineModal(connected)
      })
      this.listenerAdded = true
    }
  },
  beforeUnmount () {
    if (this.listenerAdded) {
      Network.removeAllListeners()
    }
  },
}
</script>
